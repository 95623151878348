// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import DashboardOutlined from '/home/jenkins/workspace/workspace/devops-web/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/DashboardOutlined';
import ProjectOutlined from '/home/jenkins/workspace/workspace/devops-web/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/ProjectOutlined';
import TeamOutlined from '/home/jenkins/workspace/workspace/devops-web/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/TeamOutlined';
import CrownOutlined from '/home/jenkins/workspace/workspace/devops-web/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/CrownOutlined';
import CheckCircleOutlined from '/home/jenkins/workspace/workspace/devops-web/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/CheckCircleOutlined';
import ToolOutlined from '/home/jenkins/workspace/workspace/devops-web/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/ToolOutlined';
import SettingOutlined from '/home/jenkins/workspace/workspace/devops-web/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/SettingOutlined';
export default { DashboardOutlined, ProjectOutlined, TeamOutlined, CrownOutlined, CheckCircleOutlined, ToolOutlined, SettingOutlined };

// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 获取当前的用户 GET /api/currentUser */
export async function getTokenInfo(path: string) {
  return request<API.ImileResponse<any>>('/indexBack?' + path, {
    method: 'GET',
  });
}

/** 获取 数据字典**/
export async function getDictData(typeCode: string) {
  return request<API.ImileResponse<any>>('/system/dict/data/options?typeCode=' + typeCode, {
    method: 'GET',
  });
}

/** 获取当前的用户 GET /getInfo */
export async function currentUser(options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/getInfo', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取全部用户 */
export async function listUsers(options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/system/user/selectList', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 登录人员下拉列表 */
export async function selectLoginList() {
  return request<API.ImileResponse<any>>('/system/user/selectLoginList', {
    method: 'GET',
  });
}

/** 根据角色获取用户 */
export async function listUsersByRoleKey(roleKey: any, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/system/user/selectListByRoleKey?roleKey=' + roleKey, {
    method: 'GET',
    ...(options || {}),
  });
}


/** 获取不分页系统 */
export async function listChangeSystem(options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/issue/systemList', {
    data: {},
    method: 'POST',
    ...(options || {}),
  });
}

/** 获取值班人 */
export async function listSchedulingUser(body: Object, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/release/scheduling/list', {
    data: body,
    method: 'POST',
    ...(options || {}),
  });
}

/** 获取用户系统缓存 */
export async function getUserSystemCache(options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/base/system/get-user-system-cache', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 设置用户系统缓存 */
export async function setUserSystemCache(systemId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>(
    '/base/system/set-user-system-cache?systemId=' + systemId,
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

/** 上传文件 */
export async function uploadFile(body: Object, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/oss/upload', {
    data: body,
    method: 'POST',
    ...(options || {}),
  });
}


/** 下载文件 */
export async function downloadFile(body: Object, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/oss/download', {
    data: body,
    method: 'POST',
    responseType: 'blob',
    ...(options || {}),
  });
}


/** 退出登录接口 POST /api/login/outLogin */
export async function outLogin(options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/api/login/outLogin', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 登录接口 POST /api/login/account */
export async function login(body: API.LoginParams, options?: { [key: string]: any }) {
  return request<API.LoginResult>('/api/login/account', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function pagedNoticeMsg(params: any, options?: { [key: string]: any }) {
  return request<API.ImileResponse<API.NoticeIconList>>('/sys-notice-message/paged-msg', {
    data: {
      currentPage: params.current,
      showCount: params.pageSize,
      ...params,
    },
    method: 'POST',
    ...(options || {}),
  });
}
export async function getNoticesNotReadCount(options?: { [key: string]: any }) {
  return request<API.ImileResponse<number>>('/sys-notice-message/get-not-read-count', {
    method: 'GET',
    ...(options || {}),
  });
}

export async function getNoticesTypeCount(options?: { [key: string]: any }) {
  return request<API.ImileResponse<number>>('/sys-notice-message/get-type-count', {
    method: 'GET',
    ...(options || {}),
  });
}

export async function readById(id: any, options?: { [key: string]: any }) {
  return request<API.ImileResponse<number>>('/sys-notice-message/read?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}

export async function readAll(options?: { [key: string]: any }) {
  return request<API.ImileResponse<number>>('/sys-notice-message/read-all', {
    method: 'GET',
    ...(options || {}),
  });
}

export async function delById(id: any, options?: { [key: string]: any }) {
  return request<API.ImileResponse<number>>('/sys-notice-message/del-by-id?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}



/** 根据角色查询人 */
export async function listUserByType(executorType: string) {
  return request<API.ImileResponse<any>>('/issue-executor/list-by-type?executorType=' + executorType, {
    method: 'GET',
  });
}

/** 根据角色查询人 */
export async function groupUserByType() {
  return request<API.ImileResponse<any>>('/issue-executor/group-by-type', {
    method: 'GET',
  });
}


/** 查询数据字典状态*/
export async function listProgress(body: any, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/progress-config/list-progress', {
    data: body,
    method: 'POST',
    ...(options || {}),
  });
}



/** 查询筛选条件 */
export async function getFilterContent(type: string) {
  return request<API.ImileResponse<API.FilterDetail>>('/user-filter/get-filter-content?type=' + type, {
    method: 'GET',
  });
}

/** 保存或更新筛选条件*/
export async function createOrUpdFilter(body: any, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/user-filter/create-or-upd-filter', {
    data: body,
    method: 'POST',
    ...(options || {}),
  });
}


/** 保存备注(无消息通知版) */
export async function saveRemark(param) {
  return request('/common-remark/save', {
    data: param,
    method: 'POST',
  });
}

/** 添加备注(会有消息通知) */
export async function addNoticeRemark(param) {
  return request('/common-remark/add-remark', {
    data: param,
    method: 'POST',
  });
}

/** 查询备注 */
export async function listRemark(param) {
  return request('/common-remark/list', {
    data: param,
    method: 'POST',
  });
}

/** 查询指定工作项的备注 */
export async function getWorkItemRemark(relatedType: string, relateId:string) {
  return request('/common-remark/get-work-item-remark', {
    params: { relatedType, relateId },
    method: 'GET',
  });
}

/** 删除备注 */
export async function deleteRemark(id: string) {
  return request('/common-remark/delete', {
    params: { id },
    method: 'GET',
  });
}





/** 添加阅读记录 */
export async function addRead(param) {
  return request('/common-read/add', {
    data: param,
    method: 'POST',
  });
}

/** 获取阅读情况 */
export async function getReadSituation(relateType, relateId) {
  return request('/common-read/read-situation?relateType=' + relateType + '&relateId=' + relateId, {
    method: 'GET',
  });
}


/** 获取点赞情况 */
export async function getLikeSituation(relateType:string, relateId:string) {
  return request('/common-like/like-situation?relateType=' + relateType + '&relateId=' + relateId, {
    method: 'GET',
  });
}


/** 添加点赞 */
export async function addLike(param:any) {
  return request('/common-like/add', {
    data: param,
    method: 'POST',
  });
}


/** 取消点赞 */
export async function cancelLike(param: any) {
  return request('/common-like/cancel', {
    data: param,
    method: 'POST',
  });
}

/** 添加评论 */
export async function addComment(param) {
  return request('/common-comment/add', {
    data: param,
    method: 'POST',
  });
}

/** 删除评论 */
export async function deleteComment(id) {
  return request('/common-comment/delete?id=' + id, {
    method: 'GET',
  });
}

/** 获取评论树 */
export async function getCommentsTree(relateType, relateId) {
  return request('/common-comment/get-comments-tree?relateType=' + relateType + '&relateId=' + relateId, {
    method: 'GET',
  });
}

/** 获取部门列表 */
export async function listDept() {
  return request('/user-info/list-dept', {
    method: 'GET',
  });
}

/** 获取部门成员列表 */
export async function listDeptUser(deptId: string) {
  return request('/user-info/list-dept-user?deptId=' + deptId, {
    method: 'GET',
  });
}


/** 通用驳回操作 */
export async function commonReject(param:any) {
  return request('/common-reject', {
    data: param,
    method: 'POST',
  });
}

/** 获取驳回物料信息 */
export async function getRejectMaterial(nodeType:string, nodeCode:string) {
  return request('/common-reject/get-material?nodeType=' + nodeType + '&nodeCode=' + nodeCode, {
    method: 'GET',
  });
}

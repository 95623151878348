import {
  ModalForm,
  ProFormDatePicker,
  ProFormGroup,
  ProFormInstance,
  ProFormSelect,
  ProFormText,
  ProFormItem
} from '@ant-design/pro-components';
import { useDebounceFn } from "ahooks"
import { FormattedMessage, useIntl } from '@umijs/max';
import React, { useEffect, useRef, useState } from 'react';
import type { UploadFile, UploadProps } from 'antd';
import { message, Modal, Space } from "antd";
import 'braft-editor/dist/index.css'
import { getDictData, listChangeSystem, listUsers, uploadFile } from "@/services/common/api";
import { useModel, useRequest } from "@@/exports";
import { RcFile } from "antd/es/upload";
import moment from "moment";

import { createBusinessIssue, getStagingBusinessIssueDetail, updBusinessIssue } from '@/services/rd/businessIssue/api';
import MyTinyMCEEditor from '@/components/MyTinyMCEEditor';
import { listCountryMatrix } from '@/services/rd/issues/api';
import IndustrySelect from '@/pages/Settings/Industry/components/IndustrySelect';


export type FormValueType = {
  target?: string;
  template?: string;
  type?: string;
  time?: string;
  frequency?: string;
} & Partial<API.RuleListItem>;

export type UpdateFormProps = {
  onCancel: (flag?: boolean, formVals?: FormValueType) => void;
  onSubmit: (values: API.BusinessIssueDetail) => Promise<void>;
  updateModalOpen: boolean;
  isCreated: boolean;
  detail: any;
};


// const uploadProps: UploadProps = {
//   name: 'file',
//   multiple: true,
//   action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
//
//   onDrop(e) {
//     console.log('Dropped files', e.dataTransfer.files);
//   },
// };






const IssueModalForm: React.FC<UpdateFormProps> = (props) => {
  const intl = useIntl()
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const { initialState, setInitialState } = useModel('@@initialState');
  const issueRef = useRef<ProFormInstance>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isStashId, setIsStashId] = useState('');
  const [saving, setSaving] = useState(false);
  const [state, setState] = useState('');
  const [masterLine, setMasterLine] = useState('');


  const { data: userList, run: runUserList } = useRequest(listUsers, { manual: false })
  const { data: countryList, run: runCountryList } = useRequest(listCountryMatrix, { manual: false })
  // const { data: industryList, run: runIndustryList } = useRequest((industryName: string) => {
  //   return selectIndustryList({ industryName: industryName })
  // }, { manual: true })

  const { data: systemList, run: runSystemList } = useRequest(() => {
    return listChangeSystem()
  }, { manual: false })
  const { data: stagingDetail, run: runStagingDetail } = useRequest(getStagingBusinessIssueDetail, {
    manual: true, onSuccess: (data) => {
      setIsStashId('')
      if (data) {
        setIsStashId(data?.id)
        setFields(data);
        message.success('已读取暂存需求')
      }
    }
  })

  const { data: dictData } = useRequest(
    () => {
      return getDictData('complex_rate_type,priority_level');
    },
    { manual: false },
  );







  const setFields = (detail: any) => {
    issueRef.current?.setFieldsValue({
      id: detail?.id || "",
      systemId: detail?.systemId || "",
      businessIssueName: detail?.businessIssueName || "",
      productManagers: detail?.productManagers || [],
      functionUserCodes: detail?.functionUserCodes || [],
      businessSide: detail?.businessSide || "",
      expectCompleteDate: detail?.expectCompleteDate ? detail?.expectCompleteDate : undefined,
      description: detail?.description || '',
      masterLine: detail?.masterLine || "",
      industryLineList: detail?.industryLineList || [],
      countryLineList: detail?.countryLineList || [],
      complexRate: detail?.complexRate?.toString() || '',
      priorityLevel: detail?.priorityLevel?.toString() || '',
    })
    setMasterLine(detail?.masterLine)
    setFileList(detail?.uploadAttachments)
    setState(detail?.description)
  }

  useEffect(() => {
    if (props.updateModalOpen) {
      if (props.isCreated) {
        runStagingDetail();
      }
      setIsSubmitting(false)
      if (!props.isCreated) {
        setFields(props?.detail);
      } else {
        setFileList([])
        setState('<p><strong>【需求提出人】</strong></p><p></p><p><strong>【背景】</strong></p><p></p><p><strong>【问题】</strong></p><p></p><p><strong></p>')
      }
    }
  }, [props.updateModalOpen, props.detail]);


  const onChange: UploadProps['onChange'] = async ({ file, fileList: newFileList }) => {
    if (!file.status) {
      newFileList.splice(fileList.indexOf(file), 1);
    }
    if (file.status === 'done') {
      let obj = {
        uid: newFileList[newFileList.length - 1].uid,
        name: file.response.fileName,
        status: newFileList[newFileList.length - 1].status,
        url: file.response.fileUrl,
        ...file.response
      }
      newFileList[newFileList.length - 1] = obj;
    } else if (file.status === 'error') {
      message.error(`${file.name} 文件上传失败`);
    }
    setFileList(newFileList);
  };
  const customRequest: UploadProps['customRequest'] = async ({ file, onSuccess, onError }) => {
    const fd = new FormData()
    fd.append('file', file)
    await uploadFile(fd).then(resp => {
      // 上传成功时，调用 onSuccess 并传递服务器返回的文件 URL
      onSuccess(resp?.resultObject);
    }).catch(error => {
      // 上传失败时，调用 onError 并传递错误信息
      onError(error);
    });
    ;

  };

  const beforeUpload = (file: RcFile, fileList) => {
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      // console.log(fileList.indexOf(file))
      message.error('上传文件不能超过5MB');
      return false;
    }
    return true;
  };

  const debouncedOnValuesChange =
    useDebounceFn(async (changedValues, allValues) => {
      if (!allValues.businessIssueName || !props.isCreated) return
      setSaving(true)
      if (!allValues.description) {
        allValues.description = state.editorState;
      }
      allValues.isStaging = 1;
      allValues.id = isStashId;
      let resp;
      if (isStashId) {
        resp = await updBusinessIssue(allValues);
      } else {
        resp = await createBusinessIssue(allValues);
        setIsStashId(resp?.resultObject || '');
      }
      if (resp?.status === 'success') {
        setSaving(false)
      }
    }, {
      wait: 3000
    });


  return (
    <ModalForm
      formRef={issueRef}
      submitter={{ submitButtonProps: { loading: isSubmitting } }}
      title={props.isCreated ? <Space>
        {intl.formatMessage({
          id: 'pages.common.create',
          defaultMessage: '创建',
        })}
        <span style={saving ? { color: 'blue', fontSize: 12 } : { color: 'green', fontSize: 12 }}>
          {isStashId ? (saving ? '保存中...' : '已保存到云端') : ''}
        </span>
      </Space> : <Space>
        {intl.formatMessage({
          id: 'pages.common.edit',
          defaultMessage: '编辑',
        })}
        <span style={{ color: '#rgb(53 53 53)', fontSize: 12 }}>
          （{intl.formatMessage({
            id: 'pages.issuePool.issueId',
            defaultMessage: '需求ID',
          })}:{issueRef?.current?.getFieldValue('id')}）
        </span>
      </Space>}
      width="750px"
      open={props.updateModalOpen}
      onValuesChange={(changedValues, allValues) => {
        debouncedOnValuesChange.run(changedValues, allValues);
      }}
      onFinish={async (value) => {
        if (isSubmitting) {
          return; // 如果正在提交，则阻止重复提交
        }
        if (value?.description?.length >= 65535) {
          message.error('图片上传错误，请重试！')
          return
        }
        setIsSubmitting(true);
        debouncedOnValuesChange.cancel()
        // value.description = myBrafrEditorRef?.current?.description;
        // console.log(isStashId)
        value.staging = isStashId;
        if (isStashId) {
          value.id = isStashId
        }
        if (value.masterLine === 'COUNTRY_LINE') {
          value.industryLineList = null
        }
        if (value.masterLine === 'INDUSTRY_LINE') {
          value.countryLineList = null
        }
        value.isStaging = 0;
        props.onSubmit(value);
      }}
      onOpenChange={(open) => {
        !open && props.onCancel()
      }}
      modalProps={{ destroyOnClose: true }}
    >
      <ProFormGroup>
        <ProFormText
          colProps={{ md: 12, xl: 8 }}
          name="businessIssueName"
          label={intl.formatMessage({
            id: 'pages.issuePool.issueName',
            defaultMessage: '需求标题',
          })}
          width="md"
          bordered={false}
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="pages.issuePool.form.selectIssueName"
                  defaultMessage="请输入标题"
                />
              ),
            },
          ]}
        />
        <ProFormSelect
          showSearch={true}
          name="systemId"
          width="md"
          label={intl.formatMessage({
            id: 'pages.system.title',
            defaultMessage: '系统',
          })}
          rules={[{ required: true }]}
          initialValue={initialState?.defaultSystem?.systemDTO?.systemId === 'all' ? '' : initialState?.defaultSystem?.systemDTO?.systemId}
          options={systemList?.filter(function (val) {
            return val.systemId !== 'all'
          })?.map((item) => ({ label: item.systemName, value: item.systemId, ...item }))}
        />
      </ProFormGroup>
      <ProFormGroup>
        <ProFormSelect
          showSearch={true}
          mode={'multiple'}
          name="productManagers"
          width="md"
          label={intl.formatMessage({
            id: 'pages.businessIssue.productManagerName',
            defaultMessage: '产品经理',
          })}
          options={userList?.map((item: any) => ({
            label: item.userName,
            value: item.userCode
          }))} />
        <ProFormSelect
          colProps={{ md: 12, xl: 8 }}
          showSearch={true}
          name="businessSide"
          width="md"
          label={intl.formatMessage({
            id: 'pages.businessIssue.businessSideInfo',
            defaultMessage: '提出人',
          })}
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={initialState?.currentUser?.userName}

          options={userList?.map((item: any) => ({
            label: item.userName,
            value: item.userCode
          }))} />
      </ProFormGroup>
      <ProFormGroup>
        <ProFormDatePicker
          name="expectCompleteDate"
          width="md"
          label={intl.formatMessage({
            id: 'pages.businessIssue.expectDate',
            defaultMessage: '期待日期',
          })}
          fieldProps={{
            disabledDate: (current) => {
              return current && current < moment().startOf('days');
            },
          }}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('请选择期待完成时间');
                }
                if (moment(value).endOf('day').isBefore(moment().startOf('day')) && props.isCreated) {
                  return Promise.reject('时间不能小于今天');
                }
                return Promise.resolve();
              },
            },
          ]}
        />
        <ProFormSelect
          showSearch={true}
          mode={'multiple'}
          name="functionUserCodes"
          width="md"
          tooltip={'该需求功能提出的具体人，或者具体使用用户'}
          label={'系统使用人'}
          options={userList?.map((item: any) => ({
            label: item.userName,
            value: item.userCode
          }))} />
      </ProFormGroup>
      <ProFormGroup>
        <ProFormSelect
          showSearch={true}
          colProps={{ md: 12, xl: 8 }}
          name="priorityLevel"
          width="md"
          initialValue={'3'}
          label={intl.formatMessage({
            id: 'pages.issuePool.priorityLevel',
            defaultMessage: '优先级',
          })}
          rules={[{ required: true }]}
          options={dictData?.priority_level?.map((item: any) => ({
            label: item.dictLable,
            value: item.dictValue,
          }))}
        />
        <ProFormSelect
          showSearch={true}
          name="complexRate"
          width="md"
          tooltip={
            <div>
              <div>简单 - 小于5个工作日</div>
              <div>中等 - 5~10个工作日</div>
              <div>复杂 - 大于10个工作日</div>
            </div>
          }
          label={intl.formatMessage({
            id: 'pages.issuePool.complexRate',
            defaultMessage: '复杂度',
          })}
          rules={[
            {
              // required: true,
              message: (
                <FormattedMessage
                  id="pages.issuePool.form.selectComplexRate"
                  defaultMessage="请选择复杂度"
                />
              ),
            },
          ]}
          options={dictData?.complex_rate_type?.map((item: any) => ({
            label: item.dictLable,
            value: item.dictValue,
          }))}
        />
      </ProFormGroup>
      <ProFormGroup>
        <ProFormSelect
          name="masterLine"
          label={'需求主要归属'}
          width="md"
          tooltip={''}
          rules={[{ required: true }]}
          onChange={(e) => setMasterLine(e)}
          options={[
            {
              label: '国家线',
              value: "COUNTRY_LINE",
            },
            {
              label: '行业线',
              value: "INDUSTRY_LINE",
            }
          ]} />
        <ProFormSelect
          showSearch={true}
          mode='multiple'
          name="countryLineList"
          hidden={masterLine ? masterLine !== "COUNTRY_LINE" : props?.detail?.masterLine !== "COUNTRY_LINE"}
          width="md"
          tooltip={'业务方提出的需求功能或者目标为了服务该国家，不适用于全球通用业务场景'}
          label={'国家线'}
          rules={[{ required: masterLine ? masterLine === "COUNTRY_LINE" : props?.detail?.masterLine === "COUNTRY_LINE" }]}
          options={countryList?.filter(item => item.code !== 'GLOBAL')?.map((item: any) => ({
            label: item.name,
            value: item.code
          }))} />
        <ProFormItem
          name="industryLineList"
          tooltip={'业务方提出的需求功能或者目标为了服务该垂直行业线，适用于全球通用业务场景'}
          label={'行业线'}
          hidden={!(masterLine ? masterLine === "INDUSTRY_LINE" : (props?.detail?.master))}
          rules={[{ required: masterLine ? masterLine === "INDUSTRY_LINE" : props?.detail?.masterLine === "INDUSTRY_LINE" }]}
        >
          <IndustrySelect width={328} />
        </ProFormItem>
      </ProFormGroup>



      {/* <ProFormUploadDragger width={700} name='uploadAttachments' label='上传附件' title={false} description={false}
          fieldProps={{
            customRequest: customRequest,
            fileList: fileList,
            beforeUpload: beforeUpload,
            onChange: onChange,
            onPreview: (file) => {
              setPreviewUrl(file.url || file.thumbUrl);
              setPreviewVisible(true);
            }
          }
          } /> */}
      <ProFormItem label={'需求描述'} name='description' >
        <MyTinyMCEEditor
          data={state}
          readOnly={false}
          onEdit={() => { }}
          onChange={(editorState) => {
            issueRef?.current?.setFieldsValue({ description: editorState });
          }}
          height={500}
          extendControls={undefined} />
      </ProFormItem>
      {!props.isCreated && <ProFormItem label={''} name='others'>

      </ProFormItem>}


      <Modal width={'80%'} open={previewVisible} footer={null} onCancel={() => {
        setPreviewVisible(false)
      }}>
        <iframe title="链接内容" src={previewUrl}
          style={{ width: '100%', height: document.body.offsetHeight - 200, border: 'none' }} />
      </Modal>
    </ModalForm>
  );
};

export default IssueModalForm;
